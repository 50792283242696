<template>
  <form-reallocations />
</template>

<script>
  import FormReallocations from '@/components/forms/form-reallocations'

  export default {
    name: 'create',
    components: { FormReallocations }
  }
</script>

<style scoped>

</style>
